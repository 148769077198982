import React from "react";
import { useContext } from "react";
import { store } from "../Store";
import { useLanguageVisibility } from "./hooks/use-language-visibility";

/**
 * Rederiza el dropdown de lenguaje
 * @returns {React.Component}
 */
export const DropdownLang = () => {
    const storeContext = useContext(store);
    const language = storeContext?.state?.idioma;
    const isVisible = storeContext?.state?.language?.visibleInbox;
    const languageVisibility = useLanguageVisibility();


    const updateLanguage = (lang) => {
        storeContext.dispatch({
            type: "CHANGE_VALUE",
            property: "idioma",
            value: lang
        });
        localStorage.setItem('language', lang);
        if (languageVisibility.isVisible) {
            storeContext.dispatch({
                type: "CHANGE",
                path: "language.visibleInbox",
                value: false,
            });
        }
    }

    return (
        <ul className={`dropdownLang  ${isVisible ? 'activeMode' : 'inactiveMode'} `} >
            <li className={`${isVisible ? 'activeMode' : 'inactiveMode'}`}>
                <button
                    className={`button focusable-red ${language === "es" ? "activeMode" : "inactiveMode"}`}
                    onClick={() => updateLanguage("es")}
                >
                    Español
                </button>
            </li>
            <li className={`${isVisible ? 'activeMode' : 'inactiveMode'}`}>
                <button
                    className={`button focusable-red ${language === "en" ? "activeMode" : "inactiveMode"}`}
                    onClick={() => updateLanguage("en")}
                >
                    English
                </button>
            </li>
        </ul>
    )
}