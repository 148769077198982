import React, { useContext, useState, useEffect } from "react";
import {
	store,
	librosMaxDescargados,
} from "../components/Store.js";
import { Link, useHistory } from "react-router-dom";
import MicroModal from "micromodal";
import ModalConfirmacionEliminarDescargas from "../components/ModalConfirmacionEliminarDescargas";
import useObtenerSubsecciones from "../js/translations/useObtenerSubsecciones";
import { LibroOffline } from "./LibroOffline";
import { useNetworkStatus } from "../hooks/use-network-status.js";
import { useDownloadedBooksManager } from "./hooks/use-downloaded-books-manager.js";

/**
 * Este componente se encarga de renderizar el listado de libros descargados
 * @returns {JSX.Element} Componente Descargados.
 */
const Descargados = () => {
	const { isReady, textosSubSecciones: descargados } = useObtenerSubsecciones("descargados");
	const globalState = useContext(store);
	const history = useHistory();
	const networkStatus = useNetworkStatus();
	const [message, setMessage] = useState("");
	const [description, setDescription] = useState("");
	const [deleteType, setDeleteType] = useState();
  const [bookToDelete, setBookToDelete] = useState(null);
	const downloadedBooks = useDownloadedBooksManager();
	const books = downloadedBooks.books;
	const isLoading = downloadedBooks.loading;
  const isAuth = globalState?.state?.sesionActiva === "SI";

	useEffect(() => {
		if (!isAuth) {
			history.push("/login");
		}
	}, [isAuth]);

	const showDeleteAllDialog = () => {
		MicroModal.show("modal-eliminar-descarga", {
			awaitCloseAnimation: true,
		});
		setMessage(descargados?.modal_descarga?.mensaje || "¿Estás seguro?");
		setDescription(
			descargados?.modal_descarga?.todas_las_descargas ||
				"Estás a punto de borrar todas las descargas por lo que no podrás leer estos libros cuando estés sin conexión."
		);
		setDeleteType("all");
	};

	const showDeleteOneDialog = ({ book }) => {
		MicroModal.show("modal-eliminar-descarga", {
			awaitCloseAnimation: true,
		});
		setMessage(descargados?.modal_descarga?.mensaje || "¿Estás seguro?");
		setDescription(
			`${
				descargados?.modal_descarga?.borrar_libro?.descripcion_uno ||
				"Estás a punto de borrar"
			} "${book?.title}". ${
				descargados?.modal_descarga?.borrar_libro?.descripcion_dos ||
				"por lo que no podrás leerlo cuando estés sin conexión."
			}`
		);
		setDeleteType("one");
    setBookToDelete(book?.bookId);
	};

	return (
		<>
			<>
				<div className="focusable-secondary main-container">
					<div className="container pt-5">
						<div className="surface--bg container main-content p-3 p-lg-5 mx-auto ">
							{isLoading ? (
								<div className="row mx-0 relative" style={{ minHeight: "800px" }}>
									<div className={`statistics-preloader`}></div>
								</div>
							) : (
								<>
									<h2 className="descargados-title mb-3">
										{descargados?.descargas?.titulo || "Descargas"}
									</h2>
									<div className="d-flex flex-wrap justify-content-md-between ">
										{/* <div className="col-lg-6 mb-4 mb-lg-0 order-lg-0">
											<p className="download-text onbackground-third--color">
												{descargados?.descargas?.descripcion ||
													"En esta sección podrás ver los libros que has descargado para visualizar sin conexión"}
											</p>
										</div> */}

										

										
										{/* <div className="col mb-4  order-lg-2"> */}
										<div className="col-lg-6 mb-3 order-lg-0">
											<p className="download-alert mt-3 onbackground-third--color">
												{descargados?.descargas?.alerta ||
													"Estos libros solo se quedaran guardados en este dispositivo"}
											</p>
										</div>

										<div className="col-12 mb-3  order-lg-2">
											<p
												className="app-text onbackground-third--color"
												dangerouslySetInnerHTML={{ __html: descargados?.descargas?.apps || "Para poder leer los libros descargados sin conexión necesitas el app. <a href='https://makemake.app/home?platform=windows' class='link-app'> Conoce todo sobre las apps acá.</a" }}
											></p>

											{/* <p
												className="app-txt-padding onbackground-third--color"
												dangerouslySetInnerHTML={{ __html: descargados?.descargas?.ayuda || "Si aún necesitas ayuda, <a href='https://makemake.com.co/ayuda' class='link-app'>conoce los tutoriales acá.</a>" }}
											></p> */}
										</div>

										{(() => {
											if (
												books.length === 0 &&
												networkStatus.online
											) {
												return (
													<>
														<Link
															to="/busqueda"
															className="focusable-red btn btn-primary px-3 px-sm-4 btn-primary--lower col-lg-5 px-0 d-flex justify-content-center align-items-lg-center order-lg-1 mx-auto"
															style={{maxHeight:50}}
														>
															{descargados?.descargas
																?.ver_mas_libros ||
																"Ver más libros para descargar"}{" "}
															<i className="fas fa-arrow-right ml-2"></i>
														</Link>
													</>
												);
											}
										})()}

										{/* {books.length > 0 && (
											<div className="d-flex px-0 col-lg-6 justify-content-lg-end align-items-center mt-4 mt-lg-0 order-lg-1">
												<button
													className="focusable-red btn btn-eliminar"
													onClick={() => {
														showDeleteAllDialog();
													}}
												>
													{descargados?.descargas?.boton_eliminar ||
														"Eliminar todas mis descargas"}
													<i className="far fa-trash-alt ml-2"></i>
												</button>
											</div>
										)} */}
									</div>

									<>
										{books.length > 0 ? (
											<>
												<div className="d-flex flex-wrap justify-content-between mt-5 align-items-center">
													<p
														tabIndex={0}
														className="focusable-secondary secondary-item mb-0 total-libros"
													>
														<span className="number">
															{books.length}/
															{librosMaxDescargados}
														</span>{" "}
														{descargados?.descargas
															?.libros_descargados ||
															"libros descargados"}
													</p>
													{networkStatus.online &&
														books.length <
															librosMaxDescargados && (
															<Link to="/busqueda">
																<button className="btn btn-primary px-4 mt-3 mt-md-0 btn-primary--lower">
																	{descargados?.descargas
																		?.ver_mas_libros ||
																		"Ver más libros para descargar"}{" "}
																	<i className="fas fa-arrow-right"></i>
																</button>
															</Link>
														)}
												</div>
												{books.map((book, i) => (
													<LibroOffline
														key={`item${book?.bookId}`}
                            book={book}
														onDelete={() => {
															showDeleteOneDialog({ book });
														}}
													/>
												))}
											</>
										) : (
											<>
												<>
													<h2 className="text-center secondary-item my-3">
														{descargados?.descargas?.no_has_descargado
															?.titulo ||
															"Parece que no has descargado nada aún."}
													</h2>
													<div className="col-md-9 col-lg-8 mb-4 mx-auto d-flex flex-column align-items-center">
														<div className="col-md-8 col-lg-6 mb-4 d-flex justify-content-center">
															<img
																src="/img/descarga_libros.png"
																alt="Niña leyendo"
																className="img-fluid mx-auto"
															/>
														</div>
														{(() => {
															if (networkStatus.online) {
																return (
																	<p className="onbackground-primary--color text-center px-0 col-sm-8 col-md-10 col-lg-8 col-xl-7 texto-sin-descargas">
																		{descargados?.descargas
																			?.no_has_descargado
																			?.descripcion_uno ||
																			"Los libros que descargues aparecen aquí."}
																	</p>
																);
															} else {
																return (
																	<p className="onbackground-primary--color text-center px-0 col-sm-11 col-md-12 col-lg-10 col-xl-9 texto-sin-descargas">
																		{descargados?.descargas
																			?.no_has_descargado
																			?.descripcion_dos ||
																			"Te invitamos a descargar alguno de nuestros libros cuando vuelvas a tener conexión a internet para poder disfrutar de nuestros contenidos de manera offline."}
																	</p>
																);
															}
														})()}
													</div>
												</>
											</>
										)}
									</>
								</>
							)}
						</div>
					</div>
				</div>
				{isReady && (
					<ModalConfirmacionEliminarDescargas
						message={message}
						description={description}
            onConfirm={()=>{
              if(deleteType === 'all'){
                downloadedBooks.deleteAll();
              }else{
                downloadedBooks.deleteOne({bookId:bookToDelete});
                setBookToDelete(null);
              }
            }}
						textoBotones={descargados?.modal_descarga}
					/>
				)}
			</>
		</>
	);
};

export default Descargados;
