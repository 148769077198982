import React, { useState, useEffect, useRef } from 'react';
import _ from "lodash";

/**
 * Componente de preguntas de la sección de ayuda sin login
 * @param {object} txtAyuda - textos de ayuda
 * @returns {JSX.Element} - PreguntasSinLogin
 */
export const PreguntasSinLogin = ({ txtAyuda }) => {
    const [acordeonActivo, setAcordionActivo] = useState();
    const [alturaAcordeon, setAlturaAcordeon] = useState();
    const [activador, setActivador] = useState(false);

    const contenedorAcordeon = useRef(null);

    useEffect(() => {
        datoAlturaAcordeon();
        window.addEventListener('resize', datoAlturaAcordeon)
        return () => {
            window.removeEventListener('resize', datoAlturaAcordeon)
        }
    }, [acordeonActivo, alturaAcordeon])


    const datoAlturaAcordeon = () => {
        if (!!contenedorAcordeon.current) return setAlturaAcordeon(contenedorAcordeon.current.scrollHeight);
        setAlturaAcordeon(0);
    }

    const activadorAcordeon = (index) => {
        let nuevoValor = acordeonActivo === index ? "" : index;
        setActivador(acordeonActivo === index ? false : true)
        setAcordionActivo(nuevoValor)
    }

    return (
        <>
            {
                txtAyuda &&
                Object.keys(txtAyuda).map((item, index) => {
                    return (
                        <div key={index}>
                            {Object.keys(txtAyuda[item]).map((elem) => {
                                if (elem === 'pregunta') {
                                    return (
                                        <div key={`titulo${index}`}>
                                            <button
                                                aria-expanded={activador && acordeonActivo === index}
                                                className={`focusable-secondary ${acordeonActivo === index ? "acordeon_active" : ""} acordeon px-3 py-2`}
                                                onClick={() => { activadorAcordeon(index) }}
                                            >
                                                {txtAyuda[item][elem]}
                                                <i className={`fas fa-chevron-down fa-sm ${acordeonActivo === index && "icono-activo"} `}></i>
                                            </button>
                                        </div>
                                    )
                                }
                            })}
                            {
                                activador && acordeonActivo === index ?
                                    <div
                                        tabIndex={0}
                                        ref={acordeonActivo ? contenedorAcordeon : null}
                                        style={{ maxHeight: acordeonActivo === index && activador ? ' ' : 0 }}
                                        className={`focusable-secondary opcionAcordeon ${acordeonActivo === index ? 'p-3' : 'p-0'} relative`}
                                    >
                                        {Object.keys(txtAyuda[item]).map((elemTwo, i) => {
                                            if (elemTwo === 'respuesta1') {
                                                return <p 
                                                    key={`txt_ayuda_${index}`}
                                                    dangerouslySetInnerHTML={{__html: txtAyuda[item][elemTwo] ||  "texto.." }}
                                                ></p>
                                            }

                                            if (elemTwo === 'respuesta2') {
                                                return(
                                                    <>
                                                    <p dangerouslySetInnerHTML={{__html: txtAyuda[item][elemTwo]?.txt1 ||  "texto.." }}></p>
                                                    <p dangerouslySetInnerHTML={{__html: txtAyuda[item][elemTwo]?.txt2 ||  "texto.." }}></p>
                                                    <p dangerouslySetInnerHTML={{__html: txtAyuda[item][elemTwo]?.txt3 ||  "texto.." }}></p>
                                                    </>
                                                ) 
                                            }

                                            if (elemTwo === 'lista') {
                                                return(
                                                    <ol>
                                                        <li dangerouslySetInnerHTML={{__html: txtAyuda[item][elemTwo]?.opc1 ||  "texto lista.." }}></li>
                                                        <li dangerouslySetInnerHTML={{__html: txtAyuda[item][elemTwo]?.opc2 ||  "texto lista.." }}></li>
                                                        <li dangerouslySetInnerHTML={{__html: txtAyuda[item][elemTwo]?.opc3 ||  "texto lista.." }}></li>
                                                    </ol>
                                                ) 
                                            }

                                            if (elemTwo === 'respuesta3') {
                                                return <p key={`txt_ayuda_3_${index}`} dangerouslySetInnerHTML={{__html: txtAyuda[item][elemTwo] ||  "texto.." }}>{}</p>
                                            }
                                        })}
                                    </div>
                                    : 
                                    <></>
                            }
                        </div>
                    )
                })
            }
        </>
    )
}