import React, { useEffect, useContext, useState, useRef } from "react";
import { store } from "../../components/Store.js";
import { useImmer } from 'use-immer';
import _ from 'lodash';

import normalizeString from "../../js/utilities/normalizeString";
import { cambiarSaltoLinea } from "../../js/functions/cambiarSaltoLinea";
import { ASSETS_URL } from "../../components/Store.js";
import { METADATA_TRANSLATE_FICHA } from "./translateMetadata";

import PropTypes from "prop-types";
import { ProtectedLinkFicha } from "../protected-link-ficha.js";
import { Link, useParams } from "react-router-dom";

import { useBookData } from "../useBookData.js";
import useIsMobile from "../../js/utilities/useIsMobile.js";
import { CoverSection } from "../components/coverSection.js";


/**
 * Este componente recibe los props necesarios para mostrar la información basica del libro en la sección de la Ficha. Entre sus funciones esta actualizar los filtros según el idioma activo y retornar el parametro buscable de la editorial
 * @returns InfoBasica
 */
const InfoBasica = (props) => {
  const { textoFicha, metadata, idioma } = props;

  const params = useParams();
  let book = useBookData(params?.idLibro);
  const globalState = useContext(store);
  const { state } = globalState;
  const isLogged = state.sesionActiva !== null && state.sesionActiva === "SI";

  const country = state?.pais?.toLowerCase();
  const isMobile = useIsMobile();

  // filtros  de la ficha
  const [valores, setValores] = useImmer({ nivel: '', genero: '', materia: '' });
  const expresion = /\s?\,\s?|\s\y\s?/;


  const [expanded, setExpanded] = useState(false);
  const [showButton, setShowButton] = useState(true);
  const [renderizado, setRenderizado] = useState(0);

  const refText = useRef(null);

  useEffect(() => {
    /*El estado de renderizado sirve para cargar por segunda vez el componente y obtener los valores de refText */
    if (renderizado === 0) {
      const longText = refText?.current?.scrollHeight > refText?.current?.clientHeight ? true : false;
      setShowButton(longText);
      setRenderizado(renderizado.current + 1);
      return;
    }
    const longText = refText?.current?.scrollHeight > refText?.current?.clientHeight ? true : false;
    setShowButton(longText);
  }, [idioma, metadata?.descripcion])

  useEffect(() => {
    const isLongText = () => {
      const longText = refText?.current?.scrollHeight > refText?.current?.clientHeight ? true : false;
      setShowButton(longText);
    }

    window.addEventListener('resize', isLongText);
    return () => {
      window.removeEventListener('resize', isLongText);
    }
  }, [])

  //Actualiza el texto de nivel
  useEffect(() => {
    actualizarFiltros('nivel', metadata?.nivelDeLectura);
    actualizarFiltros('genero', metadata?.genero);
    actualizarFiltros('materia', metadata?.materia);
  }, [metadata])

  /**
   * Esta función compara el METADATA con las opciones correspondientes al filtro y retorna el valor del path correspondiente al texto con traducción
   * @param {string} filtro //estado que se va a actualizar
   * @param {string} data //texto del metada
   */
  const actualizarFiltros = (filtro, data) => {
    const lista = METADATA_TRANSLATE_FICHA[filtro];
    const normalizeValue = normalizeString(data);
    lista.map((item) => {
      if (normalizeValue === item.nombre) {
        setValores(draft => { draft[filtro] = item.pathTranslate });
      }
    })
  }

  /**
   * Esta función transforma el nombre de la editorial en un parametro buscable 
   * @param {string} caracter //string: caracter que debe ser reemplazado por nuevoCaracter
   * @param {string} nuevoCaracter //string: nuevo caracter
   * @param {string} texto //string: nombre de la editorial
   * @returns 
   */
  const newUrlEditorial = (caracter, nuevoCaracter, texto) => {
    var buscarCaracter = texto.search(caracter);
    var newUrl;
    if (buscarCaracter === -1) {
      newUrl = normalizeString(texto).replace(caracter, nuevoCaracter);
      return newUrl;
    } else {
      return normalizeString(texto);
    }
  };

  return (
    <>

      {isMobile &&
        <CoverSection
          metadata={metadata}
          textoFicha={textoFicha}
        />
      }

      <div className="row">
        <div className="col-md-8 order-md-1">
          {/* Autor */}
          <div className="mb-3">
            {metadata?.autor?.split(expresion).map((item, index) => {
              return (
                <div key={`autor-${index}`} className="d-inline">
                  <ProtectedLinkFicha
                    to={`/busqueda?sch=${item}`}
                    aria-label={`${textoFicha?.info_basica?.autor || 'Autor'} ${item} `}
                    className="fichalibro-data--link focusable-secondary"
                  >
                    {item}
                  </ProtectedLinkFicha>
                  <span className="fichalibro-data--link mr-3">{metadata?.autor?.split(expresion)?.length !== (index + 1) && ','}</span>
                </div>

              )
            })}

            <p className="fichalibro-data--name">
              {metadata?.autor?.split(expresion)?.length > 1 ?
                textoFicha?.info_basica?.autores || 'Autores'
                :
                textoFicha?.info_basica?.autor || 'Autor'
              }
            </p>
          </div>
          {/* otro colaborador */}
          {(() => {
            if (metadata?.contribuidor !== "") {
              return (
                <div className="mb-3">
                  {
                    metadata?.contribuidor?.split(expresion)?.map((item, index) => {
                      return (
                        <div key={`autor-${index}`} className="d-inline">
                          <ProtectedLinkFicha
                            to={`/busqueda?sch=${item}`}
                            aria-label={`${idioma === "es" ? metadata?.funcionAutorSecundario || 'Otros' : textoFicha?.info_basica?.otros || 'Otros colaboradores'} ${item}`}
                            className="fichalibro-data--link focusable-secondary"
                          >
                            {item}
                          </ProtectedLinkFicha>
                          <span className="fichalibro-data--link mr-3">{metadata?.contribuidor?.split(expresion)?.length !== (index + 1) && ','}</span>
                        </div>
                      )
                    })
                  }

                  <p className="fichalibro-data--name">
                    {idioma === "es" ? metadata?.funcionAutorSecundario || 'Otros' : textoFicha?.info_basica?.otros || 'Otros colaboradores'}
                  </p>
                </div>
              );
            }
          })()}
          {/* editorial */}
          <div className="mb-3">
            {
              metadata?.editorial?.split(expresion)?.map((item, index) => {
                return (
                  <div key={`editorial-${index}`} className="d-inline">
                    <Link
                      to={`/editorial/${newUrlEditorial("---", "-", item || '')}`}
                      aria-label={`${textoFicha?.info_basica?.editorial || 'Editorial'} ${item}`}
                      className="fichalibro-data--link focusable-secondary"
                    >
                      {item}
                    </Link>
                    <span className="fichalibro-data--link mr-3">{metadata?.editorial?.split(expresion)?.length !== (index + 1) && ','}</span>
                  </div>
                )
              })
            }

            <p className="fichalibro-data--name">
              {metadata?.editorial?.split(expresion).length > 1 ?
                textoFicha?.info_basica?.editoriales || 'Editoriales'
                :
                textoFicha?.info_basica?.editorial || 'Editorial'
              }
            </p>
          </div>


        </div>

        <div className="col-md-12 mb-3 order-md-3">
          <div className="d-flex flex-wrap">
            {metadata?.tagArray &&
              metadata?.tagArray?.map((item, index) => {
                if (index !== 4) {
                  return (
                    <ProtectedLinkFicha
                      key={index}
                      to={idioma === "es" ? `/busqueda?sch=${item.tag}` : `/busqueda?sch=${item.tagEng}`}
                      className="ficha-btns-etiquetas px-3 py-1 mr-2 mb-2 focusable-secondary"
                    >
                      {idioma === "es" ? item?.tag || 'tag' : item?.tagEng || item?.tag || 'tagEng'}
                    </ProtectedLinkFicha>
                  )
                }
              })
            }

          </div>
          <p className="fichalibro-data--name mb-3">{textoFicha?.mas_detalles?.etiquetas || 'Etiquetas'}</p>
        </div>
      </div>

      <div>
        {/* nivel, genero y materia */}
        <div className="row">
          <div className="col-4 px-1 px-md-2">
            <ProtectedLinkFicha
              data-nivel={metadata?.nivelDeLectura}
              to={`/busqueda?nivel=${encodeURIComponent(metadata?.nivelDeLectura)}`}
              className="data-ficha-features ficha-libro-nivel focusable-secondary"
            >
              {_.get(textoFicha, valores.nivel) || 'N/A'}
            </ProtectedLinkFicha>
          </div>
          <div className="col-4 px-1 px-md-2">
            <ProtectedLinkFicha
              data-materia={metadata.materia}
              to={`/busqueda?tema=${metadata.materia}`}
              className="data-ficha-features ficha-libro-materia focusable-secondary"
            >
              <p>{textoFicha?.info_basica?.materia || 'Materia'}</p>
              <p>{_.get(textoFicha, valores.materia) || 'N/A'}</p>
            </ProtectedLinkFicha>
          </div>
          <div className="col-4 px-1 pl-md-2">
            <ProtectedLinkFicha
              className="data-ficha-features fichalibro-data--genero focusable-red d-flex justify-content-center align-items-center"
              to={`/busqueda?genero=${metadata?.genero}`}
            >
              <img
                alt=""
                className="img-fluid mx-2 d-none d-sm-block"
                src={`${ASSETS_URL}/genero/img/${normalizeString(metadata?.genero)}_blanco.svg`}
              />
              <div>
                {/* <p>{textoFicha?.info_basica?.genero || 'Género'}</p> */}
                <p>{_.get(textoFicha, valores.genero) || 'N/A'}</p>
              </div>

            </ProtectedLinkFicha>
          </div>
        </div>

        {/* Descripcion */}
        <div ref={refText} className={`fichalibro-data--text mt-4 ${expanded ? 'ver-mas' : 'txt-oculto'}`}>
          {idioma === 'es' ?
            cambiarSaltoLinea("<br>", metadata?.descripcion || 'N/A')
            :
            cambiarSaltoLinea("<br>", metadata?.descripcionEn || metadata?.descripcion || 'N/A')
          }
          {metadata?.esBilingue === 1 &&
            <p>{textoFicha?.interfaz?.bilingue || 'Este libro es bilingüe.'}</p>
          }
        </div>
        {
          showButton &&
          <div className="d-flex justify-content-end">
            <button
              onClick={() => setExpanded(!expanded)}
              className="ver-txt"
            >{expanded ? textoFicha?.botones?.ver_menos || 'Ver menos' : textoFicha?.botones?.ver_mas || 'Ver más'}</button>
          </div>
        }
      </div>
    </>

  )
}
export default InfoBasica;

InfoBasica.propTypes = {
  /**
   * Objeto que contiene los textos de la ficha. Estos se actualizan según del idioma activo
   */
  textoFicha: PropTypes.object.isRequired,
  /**
   * Objeto que contiene la información del libro 
   */
  metadata: PropTypes.object.isRequired,
  /**
   * String que contiene la abreviación del idioma activo. 'es' en caso de español - 'en' en caso del inglés
   */
  idioma: PropTypes.string,
}