import React, { useContext, useRef, useEffect } from "react";
import { Icon } from "@iconify/react";

import { useLanguageVisibility } from "./hooks/use-language-visibility";
import { store } from "../Store";
import { DropdownLang } from "./dropdown-lang";

/**
 * Rederiza el boton de lenguaje en el Header
 * @returns {React.Component}
 */
export const LangButton = () => {
    const languageVisibility = useLanguageVisibility();
    const globalState = useContext(store);
    const isAuth = globalState?.state?.sesionActiva === "SI";


    const langRef = useRef(null);

    const handleVisibility = () => {
        if (languageVisibility.isVisible) {
            languageVisibility.hide();
        }
        else {
            languageVisibility.show();
        }
    }

    /**Función que permite cerrar el dropdown dando click fuera de él*/
      useEffect(() => {
        let handler = (e) => {
          if ( !langRef.current.contains(e.target)) {
            languageVisibility.hide();
          }
        }
        document.addEventListener("mousedown", handler);
    
        return () => {
          document.removeEventListener("mousedown", handler)
        }
      }, [])

    return (
        <div ref={langRef}>
            <button
                className={`${isAuth ?
                    languageVisibility.isVisible ? 
                      "relative icon-button ml-2 activeMode" 
                      :
                      "relative icon-button ml-2" 
                  :
                    languageVisibility.isVisible ? 
                      "relative icon-button icon-responsive ml-2 activeMode" 
                      : 
                      "relative icon-button icon-responsive ml-2"
                } focusable-red`}
                onClick={() => { handleVisibility(); }}
            >
                <Icon
                    icon="clarity:language-solid"
                    width={28}
                />
            </button>

            <DropdownLang/>
        </div>

    )
}