import React, { useContext, useState, useEffect, useRef } from "react";
import { Icon } from "@iconify/react/dist/iconify.js";
import { useLocation, useHistory } from "react-router-dom";
import { useDefaultLangByCountry } from "../../../../hooks/use-default-lang-by-country";
import { useTranslations } from "../../../../hooks/use-translations";
import { LANGUAGE_RULE_COUNTRIES } from "../../../../config/constants";
import { store } from "../../../Store";
import { AccesibilidadContext } from "../../../../contexts/AccesibilidadContext";
import useIsMobile from "../../../../js/utilities/useIsMobile";
import { useNetworkStatus } from "../../../../hooks/use-network-status";
import { useAdvancedSearch } from "../../../../hooks/advanced-search/use-advanced-search";
import { useSearchBooksManager } from "../../../../hooks/advanced-search/use-search-manager";

export const SearchBooksButton = () => {
	const lang = useDefaultLangByCountry({ country: LANGUAGE_RULE_COUNTRIES, defaultLang: "en" });
	const textosInterfaz = useTranslations({ section: "header", lang: lang, array: true });
	const globalState = useContext(store);
	const { state } = globalState;
	const accebilidadState = useContext(AccesibilidadContext);

	const isLogged = state.sesionActiva !== null && state.sesionActiva === "SI";

	const location = useLocation();
	const isMobile = useIsMobile();
	const networkStatus = useNetworkStatus();

	const history = useHistory();
	const [text, setText] = useState('');

	const [esTemaClasico, setEsTemaClasico] = useState(true);
	const [buscarOver, setBuscarOver] = useState(false);

	const advancedSearch = useAdvancedSearch();
	const advancedSearchButtonRef = useRef(null);
	const searchManager = useSearchBooksManager({ text });
	const matches = Array.isArray(searchManager?.matches) ? searchManager?.matches : [];
	const [shouldShowMatchesList, setShouldShowMatchesList] = useState(false);

	const onSubmit = (e) => {
		e.preventDefault();
		search(text);
	}

	const search = (_text) => {
		history.push({
			pathname: '/busqueda',
			search: `?sch=${encodeURIComponent(_text)}`
		})
		advancedSearch.setVisible(false);
		setText("")
	}

	useEffect(() => {
		setShouldShowMatchesList(true);
	}, [text])

	/*Actualiza el estado de EsTemaClasico. */
	useEffect(() => {
		let tema = accebilidadState?.temaActual !== "clasico" ? false : true;
		setEsTemaClasico(tema);
	}, [accebilidadState]);

	/*Asigna el botón que abre las opciones de búsqueda */
	useEffect(() => {
		if (advancedSearchButtonRef.current) {
			globalState.dispatch({
				type: "CHANGE",
				path: "advancedSearch.triggerButton",
				value: advancedSearchButtonRef,
			});
		}
	}, [advancedSearchButtonRef]);

	return (
		<>
			{!isMobile &&
				isLogged &&
				networkStatus.online &&
				!location.pathname.includes("/visor") &&
				state.cantidadLibros !== "NULL" &&
				state.cantidadLibros > 40 && (
					<div className="col-10 d-flex align-items-center ml-3">
						<div className={"input-search"}>
							<button className="search-button" onClick={(e)=> onSubmit(e)}>
								<Icon icon="fa:search" className="icon-search-button" />
							</button>
							<input
								ref={advancedSearchButtonRef}
								type="text"
								value={text}
								onChange={(e) => setText(e?.target?.value)}
								onClick={() => {
									advancedSearch.setVisible(!advancedSearch?.state?.visible);
								}}
								placeholder="Búsqueda de libros"
								onMouseOver={() => setBuscarOver(true)}
								onMouseLeave={() => setBuscarOver(false)}
								onKeyDown={(event) => {
									if (event.key === "Enter") {
										onSubmit(event)
									}
								}}
							></input>
							{
								shouldShowMatchesList && text && text?.trim().length > 0 &&
								<ul className="matches-list">
									{
										searchManager?.loading ?
											<>
												<li className="p-2 w-100 text-center secondary-inverse--color">
													<Icon icon="icomoon-free:spinner9" width={30} className={'spinner-rotation'} />
												</li>
											</>
											:
											<>
												{matches?.map((match, i) => {
													return <li key={`${match?.title}-${i}`}>
														<button type="button" className="focusable-primary match-item"
															onClick={() => search(match?.title)}>
															{match?.title || 'N/A'}
														</button>
													</li>
												})}
											</>
									}
								</ul>
							}

							{advancedSearch?.state?.visible &&
								<button
									className="close-button"
									onClick={() => {
										setText("")
										advancedSearch.setVisible(false);
									}}
								>
									<Icon icon="pajamas:close-xs" className="icon-close-button" />
								</button>
							}

						</div>

					</div>


				)}
			{/* <li
						className={`btn-buscar mx-2 
							${
								isLogged &&
								!location.pathname.includes("/visor") &&
								state.cantidadLibros !== "NULL" &&
								state.cantidadLibros > 40
									? buscarOver
										? "btn-buscar"
										: "btn-buscar-general"
									: "btn-buscar"
							}
						`}
					>
						<button
							ref={advancedSearchButtonRef}
							className={`focusable-red ${
								advancedSearch?.state?.visible && "activeMode"
							} font-weight-bold`}
							onClick={() => {
								advancedSearch.setVisible(!advancedSearch?.state?.visible);
							}}
							onMouseOver={() => setBuscarOver(true)}
							onMouseLeave={() => setBuscarOver(false)}
							style={{
								color:
									isLogged &&
									!location.pathname.includes("/visor") &&
									state.cantidadLibros !== "NULL" &&
									state.cantidadLibros > 40
										? buscarOver || advancedSearch?.state?.visible
											? ""
											: esTemaClasico
											? state.datosHeader?.colorLetra !== null
												? state.datosHeader?.colorLetra
												: "var(--onsecondary-color)"
											: "var(--primary-inverse)"
										: "",
								borderColor:
									isLogged &&
									!location.pathname.includes("/visor") &&
									state.cantidadLibros !== "NULL" &&
									state.cantidadLibros > 40
										? advancedSearch?.state?.visible
											? " var(--onprimary-inverse)"
											: esTemaClasico
											? state.datosHeader?.colorLetra !== null
												? state.datosHeader?.colorLetra
												: "var(--onsecondary-color)"
											: "var(--primary-inverse)"
										: "",
							}}
						>
							{/* <i
								className="d-none d-xl-inline-block search-icon fas fa-search mr-2"
								aria-hidden="true"
							></i>{" "} *
							{textosInterfaz?.buscar || "Buscar"}
						</button>
					</li> */}
		</>
	);
};
