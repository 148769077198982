import React, { useState, useEffect, useRef, useContext } from "react";
import { Icon } from "@iconify/react";
import { useHistory, useLocation } from "react-router-dom";
import { ASSETS_URL, store } from "../../../Store";
import { AccesibilidadContext } from "../../../../contexts/AccesibilidadContext";

import { useAccessibility } from "../../../../hooks/useAccessibility";
import { useGeneralHeaderDropdown } from "../../../../hooks/header/useGeneralHeaderDropdown";

import { DropdownMiCuenta } from "./DropdownMiCuenta";
import { DropdownComunidad } from "../../public-header/components/DropdownComunidad";
import { NotificationButton } from "../../components/notification-button";
import { LangButton } from "../../../language/lang-button";
import useIsMobile from "../../../../js/utilities/useIsMobile";
/**
 * Componente con las opciones del header general
 * @param {object} textosInterfaz - contiene los textos de interfaz
 * @returns {JSX.Element} NavbarNav
 */
export const NavbarNav = ({ textosInterfaz }) => {

    const globalState = useContext(store);
    const { state } = globalState;
    const isLogged = state.sesionActiva !== null && state.sesionActiva === "SI";

    const [openDropdownCommunity, setOpenDropdownCommunity] = useState(false);
    const comunidadRef = useRef(null);

    const accessibility = useAccessibility();
    const accessibilityButtonRef = useRef(null);

    const showADropdown = useGeneralHeaderDropdown();
    const visible = showADropdown?.state?.visible;
    const dropdownButtonRef = useRef(null);
    const dropdown = useRef(null);

    const [esTemaClasico, setEsTemaClasico] = useState(true);
    const accebilidadState = useContext(AccesibilidadContext);

    const [comunidadOver, setComunidadOver] = useState(false);

    const location = useLocation();
    const isMobile = useIsMobile();

    const history= useHistory();

    /*Actualiza el estado de EsTemaClasico. */
    useEffect(() => {
        let tema = accebilidadState?.temaActual !== 'clasico' ? false : true;
        setEsTemaClasico(tema);
    }, [accebilidadState])

    /*Asigna el botón que abre el dropdown del header general */
    useEffect(() => {
        if (dropdownButtonRef.current && !isMobile) {
            globalState.dispatch({
                type: "CHANGE",
                path: "generalHeaderDropdown.triggerButtonRef",
                value: dropdownButtonRef,
            });
        }
    }, [dropdownButtonRef.current, isMobile]);

    /**Función que permite cerrar el dropdown Comunidad dando click fuera de él*/
      useEffect(() => {
        if (dropdown.current) {
          let handlerComunidad = (e) => {
            if (!dropdown.current.contains(e.target)) {
              setOpenDropdownCommunity(false)
            }
          }
          document.addEventListener("mousedown", handlerComunidad, true);
    
          return () => {
            document.removeEventListener("mousedown", handlerComunidad, true)
          }
        }
    
      }, [dropdown])

    /*Asigna el botón que abre las opciones de accesibilidad */
    useEffect(() => {
        if (accessibilityButtonRef.current) {
            globalState.dispatch({
                type: "CHANGE",
                path: "accessibility.triggerButton",
                value: accessibilityButtonRef,
            });
        }
    }, [accessibilityButtonRef]);

    /*cierra el dropdown al hacer scroll al inicio de la página */
    useEffect(() => {
        window.addEventListener("scroll", () => {
            if (window.scrollY >= 1 && window.scrollY < 10) {
                showADropdown.setVisible(false);
                setOpenDropdownCommunity(false);
            }
        });
    }, [accessibility.state.visible])



    return (
        <ul className="option-nav d-flex justify-content-end aling-items-center">

            <li
                ref={comunidadRef}
                className={`relative`}
            >
                <button
                    className={`focusable-red d-flex justify-content-center align-items-center btn-header-general
                        ${openDropdownCommunity && "openMode"}`
                    }
                    onClick={() => {
                        history.push('/club-makemake')
                        setOpenDropdownCommunity(!openDropdownCommunity);
                    }}

                    onMouseOver={() => setComunidadOver(true)}
                    onMouseLeave={() => setComunidadOver(false)}
                >
                    {textosInterfaz?.comunidad || 'Comunidad'}
                    <i className={`fas fa-chevron-down fa-sm ml-2 arrow d-none d-lg-block ${openDropdownCommunity && "active-arrow"}`}></i>
                </button>

                <div ref={dropdown} className={`dropdownMenu dropDownComunidad ${openDropdownCommunity ? 'activeMode' : 'inactiveMode'} `}>
                    <DropdownComunidad
                        setOpenDropdown={setOpenDropdownCommunity}
                        openDropdown={openDropdownCommunity}
                        textosInterfaz={textosInterfaz}
                    />
                </div>
            </li>

            <li className="relative ml-2">
                <button
                    ref={dropdownButtonRef}
                    className={`focusable-red d-flex justify-content-center align-items-center btn-header-general
                        ${visible && 'openMode'}`
                    }
                    onClick={() => {
                        setOpenDropdownCommunity(false);
                        showADropdown.setVisible(!showADropdown?.state?.visible);
                    }}
                >
                    <span className="una-linea-texto">{textosInterfaz?.mi_cuenta || 'Mi cuenta'}</span>
                    <i className={`fas fa-chevron-down fa-sm ml-2 arrow d-none d-lg-block ${visible && "active-arrow"}`}></i>
                </button>

                <DropdownMiCuenta textosInterfaz={textosInterfaz} />
            </li>

            <li className={"ml-2"}>
                <button
                    ref={accessibilityButtonRef}
                    aria-label="Ajustes -Settings"
                    className={`focusable-red ${accessibility.state.visible ? 'icon-button activeMode' : ' icon-button'} `}
                    onClick={() => { accessibility.setVisible(!accessibility?.state?.visible) }}
                >
                    <Icon
                        icon="fontisto:universal-acces"
                        width="22" height="22"
                    />
                </button>
            </li>

            <li>
                <LangButton/>
            </li>

            <NotificationButton />
        </ul>
    )
}