import React, { useState, useEffect, useRef, useContext } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { Icon } from "@iconify/react";

import { store } from "../../../Store";
import { useAccessibility } from "../../../../hooks/useAccessibility";

import { DropdownPlanes } from "./DropdownPlanes";
import { DropdownComunidad } from "./DropdownComunidad";
import { NotificationButton } from "../../components/notification-button";
import { LangButton } from "../../../language/lang-button";

/**
 * Componente con las opciones del header sin login. (Agenda un demo, Planes, libros , comunidad, iniciar sesión, ajustes, notificaciones)
 * @param {object} textosInterfaz - objeto que contiene los textos de interfaz
 * @returns {JSX.Element} NavbarNav
 */
export const NavbarNav = ({ textosInterfaz, lang }) => {

  const [openDropdown, setOpenDropdown] = useState(false);
  const [openDropdownCommunity, setOpenDropdownCommunity] = useState(false);
  const location = useLocation();
  const planesRef = useRef();
  const comunidadRef = useRef();
  const history= useHistory();

  const globalState = useContext(store);

  const accessibility = useAccessibility();
  const accessibilityButtonRef = useRef(null);

  /*Asigna el botón que abre las opciones de accesibilidad */
  useEffect(() => {
    if (accessibilityButtonRef.current) {
      globalState.dispatch({
        type: "CHANGE",
        path: "accessibility.triggerButton",
        value: accessibilityButtonRef,
      });
    }
  }, [accessibilityButtonRef]);

  /**Función que permite cerrar el dropdown dando click fuera de él*/
  useEffect(() => {
    let handler = (e) => {
      if (location.pathname !== '/institucional/formulario' && !planesRef.current.contains(e.target)) {
        setOpenDropdown(false)
      }
    }
    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler)
    }
  }, [])

  /**Función que permite cerrar el dropdown Comunidad dando click fuera de él*/
  useEffect(() => {
    if (comunidadRef.current) {
      let handlerComunidad = (e) => {
        if (location.pathname !== '/institucional/formulario' && !comunidadRef.current.contains(e.target)) {
          setOpenDropdownCommunity(false)
        }
      }
      document.addEventListener("mousedown", handlerComunidad, true);

      return () => {
        document.removeEventListener("mousedown", handlerComunidad, true)
      }
    }

  }, [comunidadRef])

  /*cierra el dropdown al hacer scroll al inicio de la página */
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY >= 1 && window.scrollY < 10) {
        setOpenDropdown(false)
        setOpenDropdownCommunity(false)
      }
    });
  }, [accessibility.state.visible])

  return (
    <>
      <ul className="option-nav d-flex justify-content-end aling-items-center">
        {location.pathname !== '/institucional/formulario' &&
          <>
            <li>
              <Link
                to={'/contacto'}
                className={`focusable-red ${location.pathname === '/contacto' || location.pathname === '/contacto/cita'  || location.pathname === '/contacto/demo' ? "contact-button activeMode" : "contact-button "}`}
              >
                {textosInterfaz?.contacto || 'Contacto'}
              </Link>
            </li>
            <li ref={planesRef} className="relative ml-2">
              <button
                className={`focusable-red ${openDropdown ? "activeModeDropdown  " : location.pathname === '/que-es/colegios' ||
                    location.pathname === '/que-es/bibliotecas' ||
                    location.pathname === '/tienda/plan-casa' ?
                    "btn-header  activeMode "
                    :
                    "btn-header"}`}
                onClick={() => {
                  setOpenDropdown(!openDropdown)
                }}
              >
                {textosInterfaz?.quiero_make || 'Planes'}
                <Icon icon="material-symbols:keyboard-arrow-down-rounded" className="icon-down"/>
              </button>

              <div className={`dropdownMenu ${openDropdown ? 'activeMode' : 'inactiveMode'} `}>
                <DropdownPlanes
                  setOpenDropdown={setOpenDropdown}
                  openDropdown={openDropdown}
                  textosInterfaz={textosInterfaz}
                />
              </div>

            </li>
            <li className="ml-1">
              <Link
                to="/libros"
                className={`focusable-red ${location.pathname === '/libros' ? "btn-header activeMode":"btn-header"}`}
              >
                {textosInterfaz?.coleccion || 'Conoce la colección'}
              </Link>
            </li>
            <li
              ref={comunidadRef}
              className={`relative ml-1`}
            >
              <button
                className={`focusable-red ${openDropdownCommunity ? "activeModeDropdown  " : location.pathname === '/club-makemake' || location.pathname === '/blog' || location.pathname === '/centro-de-recursos' ? "btn-header activeMode" : "btn-header "}`}
                onClick={() => {
                  history.push('/club-makemake')
                  setOpenDropdown(false);
                  setOpenDropdownCommunity(!openDropdownCommunity);
                }}
              >
                {textosInterfaz?.comunidad_lectora || 'Comunidad lectora'}
                <Icon icon="material-symbols:keyboard-arrow-down-rounded" className="icon-down"/>
              </button>

              <div className={`dropdownMenu dropDownComunidad ${openDropdownCommunity ? 'activeMode' : 'inactiveMode'} `}>
                <DropdownComunidad
                  setOpenDropdown={setOpenDropdownCommunity}
                  openDropdown={openDropdownCommunity}
                  textosInterfaz={textosInterfaz}
                />
              </div>
            </li>
            <li>
              <Link
                to="/login"
                className={`focusable-red ml-1 ${location.pathname === '/login' ? "btn-header activeMode" : "btn-header"}`}
              >
                {/* <Icon icon="fluent:person-circle-32-filled" width="25" height="25" className="mr-1" /> */}
                {textosInterfaz?.iniciar_sesion || 'Iniciar sesión'}
              </Link>
            </li>
          </>
        }


        <li className={`ml-2`}>
          <button
            ref={accessibilityButtonRef}
            aria-label="Ajustes -Settings"
            className={`focusable-red ${accessibility.state.visible ? 'icon-button activeMode' : 'icon-button'} `}
            onClick={() => { accessibility.setVisible(!accessibility?.state?.visible) }}
          >
            <Icon
              /* icon="material-symbols:settings" */
              icon="fontisto:universal-acces"
              width="22" height="22"
            />
          </button>
        </li>

        <li>
          <LangButton />
        </li>


        <NotificationButton />
      </ul>

    </>
  )
}