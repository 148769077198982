import React from 'react';
import { Icon } from '@iconify/react';
import { Helmet } from "react-helmet";
import { LANGUAGE_RULE_COUNTRIES } from '../config/constants.js';
import { useDefaultLangByCountry } from '../hooks/use-default-lang-by-country.js';
import { useTranslations } from '../hooks/use-translations.js';
import { PreguntasSinLogin } from './acordeones/PreguntasSinLogin.js';

/**
 * Componente de ayuda sin login
 * @returns {JSX.Element} AyudaSinLogin
 */
export const AyudaSinLogin = () => {
    const lang = useDefaultLangByCountry({ country: LANGUAGE_RULE_COUNTRIES, defaultLang: "en" });
    const texts = useTranslations({ section: "ayuda_sin_login", lang: lang });

    return (
        <>
            <Helmet>
                <title>{`${texts?.metadata?.title || 'MakeMake - Ayuda'}`}</title>
                <meta name="description" content={texts?.metadata?.description || 'Resuelve dudas y preguntas frecuentes con vídeos tutoriales de ayuda.'} />
            </Helmet>
            <div className="main-container pb-5 focusable-secondary" tabIndex={0}>
                <div className="main-content container py-5">
                    <div className='instrucciones-app'>
                        <h2 tabIndex={0} className="focusable-secondary mb-2 text-center">{texts?.instrucciones?.titulo || 'Ayuda'}</h2>
                        <div className="row m-0 mt-5">
                            {/* datos de contacto */}
                            <div className="col-lg-4 mb-3 mb-lg-0 p-0 order-lg-2">
                                <div className='datos-de-contacto'>
                                    <img src='../img/eyex-pregunta.png' className='instrucciones-app-eyex' alt={'eyex-pregunta.png'} />
                                    <h3 className='text-center  text-lg-left' >{texts?.datos_contacto?.titulo || 'Datos de contacto America latina'}</h3>
                                    <div className='mt-3 row justify-content-center justify-content-lg-start m-0'>
                                        <Icon icon="ic:round-email" width={30} />
                                        <a href={`mailto:${texts?.datos_contacto?.correo || 'contacto@makemake.com.co'}`} className='focusable-secondary ml-2'> {texts?.datos_contacto?.correo || 'contacto@makemake.com.co'}</a>
                                    </div>
                                    <div className='mt-3 row m-0 justify-content-center justify-content-lg-start align-items-start'>
                                        <Icon icon="ri:whatsapp-fill" width={30} />
                                        <div className='ml-2'>
                                            <a
                                                href={texts?.datos_contacto?.num_uno?.link || "https://wa.link/vuju91"}
                                                className="focusable-secondary d-block"
                                            >
                                                +{texts?.datos_contacto?.num_uno?.numero || '57 313 4509992'}
                                            </a>
                                            <a
                                                href={texts?.datos_contacto?.num_dos?.link || "https://wa.link/aozzbk"}
                                                className="focusable-secondary d-block"
                                            >
                                                +{texts?.datos_contacto?.num_dos?.numero || '57 350 2367037'}
                                            </a>
                                        </div>
                                    </div>
                                    <h3 className='text-center  text-lg-left mt-3' >{texts?.datos_contacto?.usa?.titulo || 'Datos de contacto Estados Unidos'}</h3>
                                    <div className='mt-3 row justify-content-center justify-content-lg-start m-0'>
                                        <Icon icon="ic:round-email" width={30} className='mt-1' />
                                        <div>
                                            <a href={`mailto:acorrea@lectorum.com`} className='focusable-secondary ml-2'> acorrea@lectorum.com</a>
                                            <br />
                                            <a href={`mailto:makemake@lectorum.com`} className='focusable-secondary ml-2'> makemake@lectorum.com</a>
                                        </div>
                                    </div>
                                    <div className='mt-3 row m-0 justify-content-center justify-content-lg-start align-items-start'>
                                        <Icon icon="ic:baseline-local-phone" width={30} />
                                        <div className='ml-2'>
                                            <p
                                                className="focusable-secondary d-block"
                                            >
                                                {`+1 (201) 559-2200`}
                                            </p>
                                            <p
                                                className="focusable-secondary d-block"
                                            >
                                                {`+1 (800) 345-5946`}
                                            </p>
                                        </div>
                                    </div>
                                </div>

                            </div>


                            {/* AYUDA PRGUNTAS */}
                            <div className="col-lg-8 p-0 pr-lg-5 order-lg-1">
                                <p className='onbackground-primary--color my-3'>{texts?.instrucciones?.description || 'MakeMake es una plataforma de suscripción a libros para niños y jóvenes diseñada especialmente para colegios y bibliotecas.'}</p>

                                <PreguntasSinLogin txtAyuda={texts?.pregunta} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}